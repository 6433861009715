import axios from 'axios'
import { user_state } from '../states/user.state'
import { useRecoilState } from 'recoil'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

export const http = axios.create({
  // baseURL: 'http://localhost:1234', // VPS Local
  // baseURL: 'https://ice-store-dashboard-backend-y8bt3.ondigitalocean.app', //VPS regular Slow
  baseURL: 'https://icestoreback.sidali.dev', //VPS Sid fast
  timeout: 0,
})

export function useAuthQuery<T>(key: string, url: string) {
  const [user, set_user] = useRecoilState(user_state)
  const history = useHistory()

  return useQuery<T>(
    [key, url],
    async () => {
      const response = await http.get(url, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      })
      return response.data
    },
    {
      onError: (error) => {
        // @ts-expect-error
        if (error.response?.status === 401) {
          set_user(null)
          history.replace('/login')
          setTimeout(() => {
            alert('Veuillez vous re-connecter')
          }, 500)
        }
      },
      refetchInterval: 60000, // 1 minute
      staleTime: 1000 * 60 * 5, // 5 minutes
      retry: false,
    },
  )
}
